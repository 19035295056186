<template>
  <div>
    <div class="d-flex">
      <b-input-group
        v-if="filter.isActive"
      >
        <b-form-input
          v-model.trim="filter.lastName"
          placeholder="Фамилия"
          @keyup.native.enter="onSearch"
        />
        <b-form-input
          v-model.trim="filter.firstName"
          placeholder="Имя"
          @keyup.native.enter="onSearch"
        />
        <b-form-input
          v-model.trim="filter.middleName"
          placeholder="Отчество"
          @keyup.native.enter="onSearch"
        />
      </b-input-group>

      <b-form-input
        v-else
        v-model.trim="filter.query"
        type="text"
        placeholder="Введите ФИО пациента"
        @keyup.native.enter="onSearch"
      />

      <b-button
        :variant="filter.isActive ? 'primary': 'outline-primary'"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2"
        @click="toggleFilter"
      >
        <b-icon icon="three-dots" />
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2"
        @click="onSearch"
      >
        <b-icon icon="search" />
      </b-button>
    </div>

    <div class="d-flex mt-2 align-items-center">
      <div class="relative">
        <b-form-input
          v-model.trim="filter.phone"
          size="sm"
          placeholder="Номер телефона"
          :style="{ width: '160px' }"
          :state="!!errors['filter.phone'] ? false : null"
          @keypress="validateNumber($event)"
          @keyup.native.enter="onSearch"
          @input="validateField('filter.phone', filter.phone)"
        />
        <div
          v-if="errors['filter.phone']"
          class="text-danger mt-2 small absolute"
        >
          {{ errors['filter.phone'][0] }}
        </div>
      </div>
      <b-form-input
        v-model.trim="filter.birthDate"
        class="ml-2 birthDay"
        size="sm"
        placeholder="Дата рождения"
        :style="{ width: '150px' }"
        @focus="(e) => e.target.type ='date'"
        @blur="(e) => filter.birthDate ? null : e.target.type ='text'"
        @keyup.native.enter="onSearch"
        @paste="onPaste"
      />

      <b-form-input
        v-model.trim="filter.policyCode"
        class="ml-2"
        size="sm"
        placeholder="Номер полиса"
        @keyup.native.enter="onSearch"
      />

      <b-form-input
        v-model.trim="filter.id"
        class="ml-2 mr-2"
        :style="{ width: '150px' }"
        size="sm"
        placeholder="ID пациента"
        @input="onIdInput"
        @keyup.native.enter="onSearch"
      />

      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-auto border-0"
        size="sm"
        :style="{ flexShrink: '0' }"
        @click="resetFilter"
      >
        Сбросить фильтры
      </b-button>
    </div>
    <div class="d-flex mt-2 align-items-center justify-content-end">
      <b-form-checkbox
        v-model="filter.isRegistered"
        size="sm"
      >
        <span class="text-secondary">
          Зарегистрирован в Доктис
        </span>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { validationMixin } from 'vuelidate';

import { mixinRoles } from '@/mixins';
import { onBInputDatePaste } from '@/helpers/utils';
import { validateData } from '@/validation/zod/main/helper.validation';
import { patientsSearchPanelValidationSchema } from '@/validation/zod/patient-search-panel/patientsSearchPanel.validation';
import { showValidationErrorMessage } from '@/helpers/messages';

export default {
  name: 'PatientsSearchPanel',
  components: {},
  mixins: [validationMixin, mixinRoles],
  model: {
    prop: 'filter',
    event: 'change',
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      insuranceSubPrograms: [],
      type: 'text',
      errors: [],
    };
  },
  async created() {
    this.insuranceSubPrograms = await this.$store.dispatch(this.$types.INSURANCE_SUBPROGRAMS_FETCH);
  },
  methods: {
    validateField(field, value) {
      const error = validateData(field, value, patientsSearchPanelValidationSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },
    validateNumber: (e = window.event) => {
      const charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))
        && charCode !== 43 // +
        && charCode !== 45 // -
        && charCode !== 40 // (
        && charCode !== 41 // )
        && charCode !== 32 // пробел
      ) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    onPaste(e) {
      onBInputDatePaste(e);
    },
    openAddModal() {
      Bus.$emit('open-modal:patient-edit');
    },
    checkBirthdayValue(val) {
      return val === '' ? 'text' : 'date';
    },
    toggleFilter() {
      this.filter.isActive = !this.filter.isActive;
    },
    resetFilter() {
      Bus.$emit('patient-search:reset-filter');
    },
    onIdInput() {
      this.filter.id = this.filter.id.replace(/[^0-9]/g, '');
    },
    onSearch() {
      this.validateField('filter.phone', this.filter.phone);
      if (Object.keys(this.errors).length > 0) {
        showValidationErrorMessage();
        return;
      }
      this.$emit('search');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
