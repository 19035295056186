<template>
  <div class="patients">
    <template>
      <async-list
        class="patient-list"
        :is-all-items-loaded="isAllItemsLoaded"
        @load-more-items="loadMoreItems"
      >
        <b-container
          fluid="xl"
        >
          <div class="d-flex pt-4 pb-4">
            <div
              class="h3 mb-0 mr-auto"
            >
              Поиск пациента
            </div>

            <b-button
              v-if="checkFeatureAccess({ name: 'Объединение пациентов', url: '/patients' })"
              variant="primary"
              size="sm"
              :type="$const.PRIMARY_BUTTON"
              @click="openPatientTransferModal"
            >
              <b-icon icon="person-dash" />
              Объединение пациентов
            </b-button>

            <b-button
              v-if="checkFeatureAccess({ name: 'Пациенты - Добавить пациента (кнопка)', url: '/patients' })"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              class="ml-2"
              size="sm"
              @click="openPatientCreateModal"
            >
              <b-icon icon="person" />
              Добавить пациента
            </b-button>
          </div>

          <patients-search-panel
            v-model="filter"
            class="patient-search-panel mb-3"
            @search="patientsFetch(true, true)"
          />

          <div
            v-if="!isSearched"
            class=""
          >
            <!-- <span
              class="result-title"
            >
              Здесь будут отображены результаты поиска
            </span> -->
          </div>
          <div
            v-else-if="!totalCount && isAllItemsLoaded"
            class=""
          >
            Ничего не найдено
          </div>

          <div
            v-else-if="totalCount"
            class="h5 pb-3"
          >
            Результаты поиска
          </div>

          <div
            v-for="(patient, index) in patients"
            :key="index"
          >
            <patient-info
              :patient="patient"
            />
          </div>

          <div>
            <div
              v-if="totalCountDeleted"
              class="h5 pt-3 pb-2"
            >
              Удаленные пользователи
            </div>

            <async-list
              v-if="isCheckDeleted"
              class="patient-list"
              :is-all-items-loaded="isAllDeletedItemsLoaded"
              @load-more-items="loadMoreItemsDeleted"
            >
              <div
                v-for="(patient, index) in patientsDeleted"
                :key="index"
              >
                <patient-info

                  :patient="patient"
                />
              </div>
            </async-list>
          </div>
        </b-container>
      </async-list>
    </template>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

// import {
//   formatISO,
// } from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

import AsyncList from '@/components/AsyncList';
import PatientInfo from '@/components/Patients/PatientInfo';
import PatientsSearchPanel from '@/components/Patients/PatientsSearchPanel';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

const LIMIT = 30;
const FETCH_DELAY = 700;
export default {
  name: 'Patients',
  page: {
    title: 'CRM Doctis - Пациенты',
  },
  components: {
    AsyncList,
    PatientInfo,
    PatientsSearchPanel,
  },
  mixins: [mixinRoles],
  data() {
    return {
      isSearched: false,
      isAllItemsLoaded: true,
      isAllDeletedItemsLoaded: true,
      isLoading: false,
      isCheckDeleted: false,
      limit: LIMIT,
      filter: {
        query: '', // still empty
        id: null,
        isActive: false,
        phone: '',
        birthDate: null,
        isRegistered: null,
        policyCode: null,
        // subProgramId: null,
        lastName: null,
        firstName: null,
        middleName: null,
      },
    };
  },
  computed: {
    patients() {
      return this.$store.state.Patients.patients;
    },
    patientsDeleted() {
      return this.$store.state.Patients.patientsDeleted;
    },
    totalCount() {
      return this.patients.length;
    },
    totalCountDeleted() {
      return this.patientsDeleted.length;
    },
  },
  watch: {
    filter: {
      handler(filter) {
        localStorage.setItem('patient-filter', JSON.stringify(filter));
      },
      deep: true,
    },
  },
  created() {
    this.patientsFetchDebounced = debounce(this.patientsFetch, FETCH_DELAY);
    Bus.$on('patients:update', this.updatePatients);
    Bus.$on('patient-search:reset-filter', this.resetFilter);

    if (localStorage.getItem('patient-filter')) {
      try {
        const filter = JSON.parse(localStorage.getItem('patient-filter'));
        this.filter = {
          ...filter,
          birthDate: filter.birthDate ? new Date(filter.birthDate) : null,
        };
      } catch (e) {
        localStorage.removeItem('patient-filter');
      }
    }
  },
  beforeDestroy() {
    this.$store.commit(this.$types.PATIENTS_SET, []);
    this.$store.commit(this.$types.PATIENTS_DELETED_SET, []);
    Bus.$off('patients:update', this.updatePatients);
    Bus.$off('patient-search:reset-filter', this.resetFilter);
  },
  methods: {
    openEditModal() {
      // Bus.$emit('open-modal:patient-edit', null);
    },
    async patientsFetch(clear, isChecked, isUpdate) {
      this.isSearched = true;
      if (this.isLoading) return;

      if (clear) {
        this.$store.commit(this.$types.PATIENTS_DELETED_SET, []);
      }
      if (isChecked) this.isCheckDeleted = false;

      this.isAllItemsLoaded = false;
      this.isAllDeletedItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;
      const prevTotalDeleted = clear ? 0 : this.totalCountDeleted;

      const prevQuery = this.filter.query;
      const requestObject = {
        skip: prevTotal,
        take: this.limit,
        query: prevQuery,
        clear: !!clear,
        save: true,
        filter: { ...this.filter },
      };
      const requestObjectDeleted = {
        skip: prevTotalDeleted,
        take: this.limit,
        query: prevQuery,
        clear: !!clear,
        save: true,
        filter: { ...this.filter },
      };
      if (requestObject.filter.birthDate) requestObject.filter.birthDate = new Date(requestObject.filter.birthDate).toISOString();

      try {
        if (!this.isCheckDeleted || isUpdate) await this.$store.dispatch(this.$types.PATIENTS_FETCH, requestObject);

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
          this.isCheckDeleted = true;
          if (!isUpdate
            && (requestObject.filter.birthDate
            || requestObject.filter.query.length
            || requestObject.filter.lastName
            || requestObject.filter.firstName
            || requestObject.filter.middleName
            || requestObject.filter.policyCode
            || requestObject.filter.isRegistered
            || requestObject.filter.phone
            || requestObject.filter.id)
          ) await this.$store.dispatch(this.$types.PATIENTS_DELETED_FETCH, requestObjectDeleted);
        }
        if (prevTotalDeleted + this.limit > this.totalCountDeleted) {
          this.isAllDeletedItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;

        if (this.filter.query !== prevQuery) {
          this.patientsFetch(true);
        }
      }
    },
    loadMoreItems() {
      this.patientsFetch(false);
    },
    loadMoreItemsDeleted() {
      this.patientsFetch(false);
    },
    async updatePatients(id) {
      if (!id) {
        await this.patientsFetch(true, true, true);
        // TODO: что это такое?
        await this.patientsFetch(true, false);
        return;
      }
      if (this.patients.find((patient) => patient.id === id)) {
        this.patientFetch(true);
      }
    },
    openPatientCreateModal() {
      uiService.showModal(MODALS.PATIENT_CREATE_SELECT_MODE, {
        actions: {
          success: () => {
            this.updatePatients();
          },
        },
      });
    },
    openPatientTransferModal() {
      uiService.showModal(MODALS.PATIENT_TRANSFER_MODAL, {
        name: 'PatientTransferModal',
      });
    },
    resetFilter() {
      localStorage.removeItem('patient-filter');
      this.filter = {
        query: '',
        isActive: false,
        phone: '',
        birthDate: null,
        isRegistered: null,
        policyCode: null,
        subProgramId: null,
        lastName: null,
        firstName: null,
        middleName: null,
      };

      this.$store.commit(this.$types.PATIENTS_SET, []);
      this.$store.commit(this.$types.PATIENTS_DELETED_SET, []);
      this.isSearched = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.patients {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.result-title {
  @extend .page-title;
  margin-right: auto;
}

.patient-list {
  height: 100%;
  transition: height 0.3s linear;
  box-sizing: border-box;
}

.result-row {
  flex: 1;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
</style>
