/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

export const patientsSearchPanelValidationSchema = z.object({
  filter: z.object({
    phone: z
      .string()
      .optional()
      .refine((value) => {
        if (!value || value.trim().length === 0) {
          return true;
        }
        const cleanedValue = value.replace(/\D/g, '');
        return (
          (cleanedValue.length === 11 && (cleanedValue.startsWith('7') || cleanedValue.startsWith('8')))
          || ((cleanedValue.length === 12 || cleanedValue.length === 13) && cleanedValue.startsWith('55'))
        );
      }, 'Некорректное значение поля')
      .refine((value) => {
        if (!value || value.trim().length === 0) {
          return true;
        }
        return (/^(?:\+7|7|8)\D*(\d\D*){10}$/.test(value) || /^(?:\+55|55)\D*(\d\D*){10,11}$/.test(value));
      }, 'Некорректное значение поля'),
  }),
});
